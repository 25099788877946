import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className="p-10 bg-gray-100">
    <h2 className="text-3xl font-bold mb-4 text-black">{t('contact.title')}</h2>
    <p className="text-gray-700 mb-4">{t('contact.subtitle')}</p>
    <p className="mb-4"><strong>{t('contact.phone')}:</strong> +91-9926877257</p>
    <p className="mb-4"><strong>{t('contact.email')}:</strong> contact@cirkitex.com</p>
    <p><strong>{t('contact.address')}:</strong> Cirkitex Semiconductor Private Limited
    131 RN CITY, AIRPORT ROAD, INDORE (M.P.) 452005 India</p>
    <p className="text-gray-700 leading-relaxed mt-6">{t('contact.more_info')}</p>
  </section>
  );
};

export default Contact;