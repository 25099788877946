import React, { useState } from 'react';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Services/Services';
import Products from './components/Products/Products';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import './App.css';

import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Team from './components/Team/Team';

function App() {
  const [language, setLanguage] = useState('en');

  const handleLanguageChange = (e) => {
    const lang = e.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div className="App flex flex-col min-h-screen">
          <Header onLanguageChange={handleLanguageChange} language={language} />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              {/* <Route path="/services" element={<Services />} />
              <Route path="/products" element={<Products />} /> */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/team" element={<Team />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;